import React, { useEffect, useState } from 'react';
import '../App.scss';
import Landing from '../Landing/Landing.component';
import { IRef, IRefs } from '../types';
import Sections from '../Sections/Sections.component';
import Team from '../Team/Team.component';

function isInViewport(element: HTMLElement) {
  var bounding = element.getBoundingClientRect();
  return (bounding.top <= 10 && (-1 * bounding.top + 10) < bounding.height);
}

function handleScrollMadeVisible(refs: IRefs, setCurrentPage: React.Dispatch<React.SetStateAction<string>>) {
  let min = 1000000000;
  let minName;
  for (const [key, ref] of Object.entries(refs)) {
    const current = ref.current;
    if (current === null) {
      continue;
    }
    const isVisible = isInViewport(current);
    if (isVisible && (current.offsetTop < min)) {
      min = current.offsetTop;
      minName = key;
    }
  }
  if (typeof minName === "string" && window.location.hash !== "#" + minName) {
    window.history.pushState({
      pageId: minName,
    }, minName, "/#" + minName);
    setCurrentPage(minName);
  }
}

const refs: IRefs = {};
const PageContent = ({
  setCurrentPage,
}: {
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [loaded, setLoaded] = useState(false);
  function setRef(ref: IRef, name: string) {
    refs[name] = ref;
  }

  useEffect(() => {
    if (!loaded) {
      if (window.location.hash) {
        const a = document.createElement("a")
        a.href = window.location.hash;
        a.style.display = "None";
        document.body.append(a);
        a.click()
        a.remove()
      }
      setLoaded(true)
    }
  }, []);

  return (
    <div id="scrollContainer" className="page" onScroll={() => handleScrollMadeVisible(refs, setCurrentPage)}>
      <Landing setRef={setRef} />
      {/* <Team setRef={setRef} /> */}
      <br/>
      <Team setRef={setRef} />
      <br/>
      <Sections setRef={setRef} />
      <br/>
      <br/>
    </div>
  );
}

export default PageContent;