import React, { useEffect, useRef } from "react";
import { ISetRef } from "../types";
import "./sections.scss";
import ImageContainer from "../Images/ImageContainer.component";
import "../Images/images.scss";

interface ISection {
    name?: string;
    content?: string;
    html?: any;
    img?: string;
    alt?: string;
    setRef: ISetRef;
}

const Section = ({
    name,
    content,
    html,
    img,
    alt,
    setRef,
}: ISection) => {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (name) {
            setRef(ref, name.toLowerCase()?.replace(" ", ""));
        }
    }, [ref.current]);

    return <div id={name?.toLowerCase()?.replace(" ", "")} className="paper-paragraph" ref={ref}>
        {name && <h4>{name}</h4>}
        {content && <p>{content}</p>}
        {(html !== undefined) && html}
        {(img && alt) && <ImageContainer src={img} alt={alt} />}
    </div>
}

export default Section;