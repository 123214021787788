import React from "react";
import "./images.scss";

const ImageContainer = ({
    src,
    alt,
}: {
    src: string,
    alt: string,
}) => {
    return (
        <div className="image-container">
            <div className="image-container-frame">
                <img src={src} alt={alt} />
            </div>
        </div>
    );
}

export default ImageContainer;