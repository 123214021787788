import { ISetRef } from "../types";
import "./sections.scss";
import Section from "./Section.component";
import Projects from "../Projects/Projects.component";
import Gallery from "../Gallery/Gallery.component";

const sections = [
    {
        name: "Background",
        content: "After a lifetime of hacking games and working on code, I take a great deal of pride in my work as a reflection of how far I've come.",
    },
    {
        content: "There is a sense of peace and fulfillment with good, clean work. It's quiet and unassuming because nobody raises alarms or takes interest in something that works as it's supposed to.",
    },
    {
        content: "I earnestly believe in the power of managing expectations to allow engineers to carefully plan, write, and test their code. Even in my role, with an abundance of responsibilities to manage, when it comes time to write code I take as much time as I need to write fully tested and planned code.",
    },
    {
        content: "Maybe that gives you some insight into the kind of person I am. I have a strong attention to detail, and I take nothing for granted. I carry myself with pride and I delight in the beauty of engineering."
    },
    {
        content: "My education in Computer Engineering gave me an understanding about underlying hardware, software, and networking protocols. While I have been exposed to software most of my life, my education is what kicked my skills into high gear."
    },
    {
        content: "I feel it's important to say that I believe that engineers have the potential to be great regardless of their educational background -- I have known great engineers on both extremes. The key thing that sets great engineers apart is not experience or knowledge; it's about grit and determination. If you have the willpower to be great, you will be great.",
    },
    {
        content: "As a leader, I seek to encourage and bring out the best in my team. I work hard to educate, to instill pride in good work, and to inspite grit and determination. I lead by example by jumping in to code and demonstrate best practices, and I expect high quality work from my team beause to expect any less does a disservice to them.",
    },
    {
        content: "As an individual contributor, I will never stop learning the latest and greatest technologies."
    },
    {
        name: "Interests",
        content: "Something that has held my interest for the better part of this last decade is machine learning and artificial intelligence. Throughout university I took every opportunity to apply ML to term projects, and my year-long senior project was entirely focused on applications in ML. I also received my certificate in data science in 2022.",
    },
    {
        content: "I have not had the opportunity to make good use of the skills that I've accumulated there in a professional capacity, but I have continued to maintain them at least at an enthusiast level."
    },
    {
        content: "In addition to statistics, data sciene, and AI/ML, systems architecture, scalability, and design patterns are always of interest to me. Please send book suggestions my way if you have found something that really speaks to you.",
    },
    {
        content: "Astronomy and cosmology hold another large part of my interest. My passion for outer space is what led me to pursue a higher education in the first place, and I have maintained that passion ever since.",
    },
    {
        content: "I love photography, both as an excuse to go explore the world around me and as an art form. I tend to focus on cityscapes when I do go out for this."
    },
    {
        content: "I also play video games when I get a chance, mostly single player these days. Things like Jedi Survivor, Mass Effect, OneShot, and Shadows of Doubt.",
    },
    {
        name: "Projects",
        content: "Below are some of my favorite projects from the past that I can discuss publicly.",
    },
    {
        html: <Projects />,
    },
    {
        name: "Contact",
        html: <div>
                    You can reach me any time at{' '}
                    <a href="mailto:breeanabellflower@gmail.com">breeanabellflower@gmail.com</a>
                    <br/>
                    Note: Please do not reach out about sales opportunities. I know it's a challenging job, and I don't want to waste your time!
                </div>
    },
    {
        name: "Good Reads",
        html: <div style={{
            display: "flex",
            flexDirection: "column",
        }}>
            <div className="cite">
                <h4 style={{
                    marginTop: 0,
                }}>
                    Signals and Systems
                </h4>
                <h5>Oppenheim et al. - Prentice-Hall - 1997</h5>
            </div>
            <div className="cite">
                <h4>Operating System Concepts, 9th Edition</h4>
                <h5>Silberschatz, Galvin & Gagne - John Wiley & Sons - 2013</h5>
            </div>
            <div className="cite">
                <h4>Logic and computer design fundamentals</h4>
                <h5>Mano et al. - Pearson Education Limited - 2016</h5>
            </div>
            <div className="cite">
                <h4>The Zen of Python</h4>
                <h5>Tim Peters - <a href="https://peps.python.org/pep-0020/">SOURCE</a>{" "}- 2004</h5>
            </div>
        </div>
    },
    {
        name: "Gallery",
        html: <Gallery />,
    },
];

const Sections = ({
    setRef,
}: {
    setRef: ISetRef,
}) => {
    return (
        <div className="paper-root paper">
            {
                sections.map((paragraph, key) =>
                    <Section
                        name={paragraph.name}
                        content={paragraph.content}
                        html={paragraph.html}
                        setRef={setRef}
                        key={key}
                    />
                )
            }
            <br />
            <br />
            <br />
            <br />
        </div>
    );
}

export default Sections;