import React, { useEffect, useRef } from 'react';
import { ISetRef } from '../types';
import poster from "./images/poster.png";
import bg from "./images/network_-_80645 (540p).mp4";
import "./landing.scss";

const Landing = ({
    setRef,
}: {
    setRef: ISetRef;
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        setRef(ref, "landing");
    }, [ref.current]);

    return <div id="landing" className="landing-root" ref={ref}>
        <video id="looper" autoPlay loop muted poster={poster}>
            <source src={bg} type="video/mp4" />
        </video>
        <div className='landing-cover'>
            <div className='landing-cover-text'>
                <h1>
                    Hey, I'm Breeana.
                </h1>
                <h2>
                    Most people call me Bree.
                </h2>
            </div>
        </div>
    </div>
};

export default Landing;