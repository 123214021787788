import React, { useState } from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navigator from './Navigator/Navigator.component';
import PageContent from './PageContent/PageContent.component';

function App() {
  const [currentPage, setCurrentPage] = useState(window.location.hash.substring(1))

  return (
    <div id="Landing" className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/*"  element={<Navigator page={currentPage} />} />
        </Routes>
        <Routes>
          <Route path="/*" element={<PageContent setCurrentPage={setCurrentPage} />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
