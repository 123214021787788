import React from "react";
import ImageContainer from "../Images/ImageContainer.component";

const Project = ({
    name,
    src,
    description,
}: {
    name: string;
    src: string;
    description: JSX.Element;
}) => {
    return (
        <div className="project">
            <h2>{name}</h2>
            <ImageContainer src={src} alt={name + " image"} />
            {description}
        </div>
    );
}

export default Project;