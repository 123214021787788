import React from "react";
import d365fs from "./images/mobile-2020-booking-view.png";
import acess from "./images/acess.png";
import ref from "./images/refinementrunning.gif";
import terminal from "./images/terminal.png";
import nn from "./images/neuralnet.jpg";
import Project from "./Project.component";

const projects = [
    {
        name: "Dynamics 365 Field Service Calendar",
        src: d365fs,
        description: <div className="project-description">
            On a project with Microsoft, the aim of this project was to develop existing Outlook functionality and UX for the Dynamis 365
            Field Service mobile and desktop application.
            <br />
            <br />
            Flawless functionality was expected for 3 different calendar views and booking pages across all browsers as well as
            Android and iOS devices. Additionally, screen reader compatibility was required on all devices as well.
            <br />
            <br />
            API interfacing, infinite scrolling, offline mode, and browser- and device-specific compatibility issues
            were the greatest challenges for this project.
        </div>
    },
    {
        name: "Ace Senior Services",
        src: acess,
        description: <div className="project-description">
            On a project with Ace Hardware, the aim of this project was to develop new gig economy application. I led and participated in discussions about
            overarching designs, solutioning, and security. I also introduced Azure Boards to the company, and developed the full agile workflow
            in that system to track progress on the project.
            <br />
            <br />
            In terms of implementation, I completed two of the three websites required for this gig economy appliation -- the client-facing website and
            the gig-worker facing website / mobile application. Work for this included design and collaboration for every new back-end API, Stripe integration, Azure authentication
            integration, extensive accessibility testing, responsive and adaptible UX, and unit testing.
            <br />
            <br />
            The largest challenge for this project was ideation during development, followed by a severe resource crunch that left me with almost all work on
            the two websites I mentioned above.
        </div>
    },
    {
        name: "Refinement: A Web-Based Civilization Simulator",
        src: ref,
        description: <div className="project-description">
            Refinement was a website that hosted a computer-vs-computer civilization simulation. All I wanted to do with this project
            was to have effectively an ant-farm that I could watch along with my friends.
            <br />
            <br />
            Refinement was designed with a neo-retro feel and a chatroom for discussing the events taking place in the game. The simulation was
            computed from start to finish on regular intervals and then sent in chunks in a highly compressed format via websockets to all viewers of the site.
            The frame being viewed was then synchronized to the clock so that all viewers would see the same simulation at the same time.
            <br />
            <br />
            The canvas API, maintaining high performance, and developing methods for compressing and decompressing game data were the largest challenges for this project.
        </div>
    },
    {
        name: "Custom Operating System",
        src: terminal,
        description: <div className="project-description">
            The aim of this project was to develop an operating system that mirrored the capabilities of Linux, including many terminal commands, process scheduling, memory
            allocation, file system structure, and hardware interfacing. Several protections were added such as semaphor locks and administration of user-specific privileges.
            <br/><br/>
            The most challenging aspect of this kind of low level programming was how challenging it was to debug issues. It ended up being an incredibly rewarding project
            that gave me a really solid understanding of low-level computing. Most computer programs at university include a project such as this, but I mention it here because
            I feel it was the university project that gave me my greatest insights into how software works in general.
        </div>
    },
    {
        name: "Verilog-based Neural Network",
        src: nn,
        description: <div className="project-description">
            This project, the first in my graduating year and the primary focus of my senior project, was about designing and implementing a neural network in a verilog HDL environment.
            I laid out the mathematical model of the neural network and sketched diagrams of the hardware design.
            <br/><br/>
            Ultimately I landed on making use of a sigmoid approximation described by a few academic papers to describe the activation function. This allowed a simulaton of the artificial neurons in verilog to
            produce results nearly the same as an equivalent software model.
            <br/><br/>
            A lot of the challenge involved here was determining how to represent a non-linear activation function in our hardware. Another big hurdle was writing high precision floating point functionality from scratch.
            In terms of raw number of hours spent hitting my head against something this still stands as my greatest challenge to date. Even debugging a simple verilog design can be tedious and difficult!
        </div>
    },
]

const Projects = () => {
    return (
        <div className="projects">
            {projects.map((project, i) => {
                return <Project name={project.name} src={project.src} description={project.description} key={i} />
            })}
        </div>
    );
}

export default Projects;