import React from "react";
import breeanabellflowerlogo from "./breeanabellflowerlogo.png";

const Logo = () => (
  <div className="logo-container" style={{
    position: "relative",
    margin: "auto",
  }}>
    <img src={breeanabellflowerlogo} style={{
      width: "100%",
    }} />
  </div>
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="100%"
  //   height="100%"
  //   viewBox="0 0 2568 489"
  //   fill="white"
  // >
  //   <text id="BREEANA_BELLFLOWER_PORTFOLIO" data-name="BREEANA BELLFLOWER PORTFOLIO" className="cls-1" x="1279.552" y="167.445">
  //     <tspan x="1279.552">BREEANA BELLFLOWER</tspan>
  //     <tspan className="cls-2" x="1279.552" dy="180">PORTFOLIO</tspan>
  //   </text>
  // </svg>
);

export default Logo;