import React from "react";
import "./teamMember.scss";

interface TeamMemberProps {
    name: string;
    info: string;
    src: string;
    links: string[];
}

const TeamMember = ({
    name,
    info,
    src,
    links,
}: TeamMemberProps) => {
    return (
        <div className="team-member-card">
            <div className="team-member-card-border top-left" />
            <div className="team-member-card-border top-right" />
            <div className="team-member-card-border bottom-left" />
            <div className="team-member-card-border bottom-right" />
            <div className="team-member-card-background">
                <div className="team-member-headshot">
                    <img className="team-member-headshot-image" src={src} alt={"Headshot of " + name} />
                </div>
            </div>
            <div className="team-member-card-background">
                <h3>{name}</h3>
                <h4>{info}</h4>
                <div>
                    {links.map((link, key) => {
                        const where = link.split(".com")[0].split("www.")[1];
                        return <h5 key={key}><a href={link}>{where}</a></h5>;
                    })}
                </div>
            </div>
        </div>
    );
}

export default TeamMember;