import React, { useEffect, useRef } from "react";
import { ISetRef } from "../types";
import "./team.scss";
import TeamMember from "./TeamMember.component";
import Breeana from "./images/breeana.png";

const team = [
    {
        name: "Breeana Bellflower",
        info: "Director of Enginering at OneRail, data science and AI/ML enthusiast, astronomy and photography hobbyist, gamer.",
        src: Breeana,
        links: [
            "https://www.linkedin.com/in/breeana-bellflower/",
            "https://www.instagram.com/breeanabellflower/",
        ],
    },
]

const Team = ({
    setRef,
}: {
    setRef: ISetRef,
}) => {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setRef(ref, "profile");
    }, [ref.current]);

    return (
        <div id="profile" className="team-root" ref={ref}>
            {
                team.map((member, key) =>
                    <TeamMember
                        name={member.name}
                        info={member.info}
                        src={member.src}
                        links={member.links}
                        key={key}
                    />
                )
            }

        </div>
    );
}

export default Team;