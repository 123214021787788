import { useState } from 'react';
import "./navigator.scss";
import Logo from './logo.svg';

function pushPageState(page_: string) {
    const page = page_.toLowerCase().replace(" ", "");
    window.history.pushState({
        pageId: page,
    }, page, "/#" + page);
    const scrollContainer: HTMLElement | null = document.getElementById("scrollContainer");
    const elem: HTMLElement | null = document.getElementById(page);
    if (!elem || !scrollContainer) {
        return;
    }
    const y = elem.offsetTop;
    scrollContainer.scrollTo({
        top: y,
        behavior: "smooth",
    });
}

function getPathnameFromElement(element: string): string {
    const words = element.replace(" ", "");
    return words.toLowerCase();
}

const pages = [
    "Landing",
    "Profile",
    "Background",
    "Interests",
    "Projects",
    "Contact",
    "Good Reads",
    "Gallery",
];

const Navigator = ({
    page,
}: {
    page: string;
}) => {
    const [open, setOpen] = useState(false);
    return <>
        <div className='navigator-drawer-button'
            onClick={() => {
                setOpen(!open);
            }}
        >
            <div className={'ndb-top ' + (open ? "on" : "off")}/>
            <div className={'ndb-mid ' + (open ? "on" : "off")}/>
            <div className={'ndb-low ' + (open ? "on" : "off")}/>
        </div>
        <div className={"sidebar-navigator navigator-root " + (open ? "on" : "off")}>
            <div
                className='navigator-title'
                onClick={() => {
                    setOpen(false);
                    pushPageState(getPathnameFromElement(pages[0]));
                }}
            >
                <Logo />
            </div>
            <div className='navigator-options'>
                {
                    pages.slice(1).map((element, index) => {
                            const isSelected = page.toLowerCase() === getPathnameFromElement(element);
                            return <div
                                key={index}
                                className='navigator-option'
                                id={isSelected ? "navigator-option-selected" : undefined}
                                onClick={() => {
                                    setOpen(false);
                                    pushPageState(getPathnameFromElement(element));
                                }}
                            >
                                <span>{element}</span>
                                <div className='top-block' style={!isSelected ? {opacity:0} : undefined}>
                                    <div className='behind-block' />
                                    <div className='curve-block' />
                                </div>
                                <div className='bottom-block' style={!isSelected ? {opacity:0} : undefined}>
                                    <div className='behind-block' />
                                    <div className='curve-block' />
                                </div>
                            </div>
                        }
                    )
                }
            </div>
        </div>
    </>
};

export default Navigator;